import React, { useEffect, useState } from "react";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../AuthModule";
import { useMsal } from "@azure/msal-react";
import {
  Alert,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grow,
  IconButton,
  List,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import CardHeader from "../Components/CardHeader";
import CardComponent from "../Components/CardComponent";
import ConfirmationDialog from "../Components/ConfirmationDialog";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import ProjectListItem from "../Components/ProjectListItem";
import useTemplateApi from "../Api/TemplateApi";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProjectListItemCheck from "../Components/ProjectListItemCheck";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import useOrganisatorId from "../hooks/useOrganisatorId";

interface ProjectData {
  id: string;
  name: string;
  organisatorId: string;
  checked?: boolean;
  visibility: "public" | "private";
}

interface Slide {
  title: string;
  name: string;
}

const MyProjectsView = () => {
  const { instance } = useMsal();

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiProject.scopes.write,
  });

  const [projects, setProjects] = useState<ProjectData[] | null>(null);
  const [organisatorId, setOrganisatorId] = useState<string | null | undefined>(
    null,
  );
  const userId = useOrganisatorId();
  const [slides, setSlides] = useState<Slide[]>([]);
  const [deleteProjectView, setDeleteProjectView] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const templateApi = useTemplateApi();
  const [checkedProjects, setCheckedProjects] = useState<{
    [id: string]: boolean;
  }>({});

  const isAnyProjectChecked = Object.values(checkedProjects).some(
    (value) => value,
  );

  function handleDeleteCheck(id: string, checked: boolean) {
    setCheckedProjects({ ...checkedProjects, [id]: checked });
  }

  function handleDeleteClickButton() {
    setDeleteProjectView(true);
  }

  function handleBackButton() {
    setDeleteProjectView(false);
  }

  function handleDelete() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }

  async function handleProjectDelete() {
    const projectsToDelete = Object.keys(checkedProjects)
      .filter((id) => checkedProjects[id])
      .join(",");

    console.log("Projects to delete:", projectsToDelete);

    // Debugging-Anweisung zur Überprüfung der URL
    console.log(
      `Löschen von Projekten mit URL: ${protectedResources.apiProject.endpoint}${projectsToDelete}`,
    );

    let token = (
      await instance.acquireTokenSilent({
        scopes: protectedResources.apiProject.scopes.write,
      })
    ).accessToken;

    fetch(`${protectedResources.apiProject.endpoint}${projectsToDelete}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ projectIds: projectsToDelete }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Projekte erfolgreich gelöscht");
          setOpen(false);
          setDeleteProjectView(false);
          window.location.reload();
        } else {
          console.log(`Fehler ${response.status}: ${response.statusText}`);
          setOpen(false);
          setDeleteProjectView(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("Fehler beim Löschen der Projekte:", error);
      });
  }

  useEffect(() => {
    templateApi.getTemplates().then((response) => {
      if (response?.body != null) {
        setSlides(response.body);
      }
    });
  }, []);

  useEffect(() => {
    if (!projects) {
      execute("GET", protectedResources.apiProject.endpoint).then(
        (response) => {
          if (response?.body !== undefined) {
            setProjects(response.body);
          }
        },
      );
    }

    setOrganisatorId(userId);
  }, [execute, projects, instance, userId]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const renderLoading = () => (
    <Stack spacing={2} sx={{ alignItems: "center", p: 5 }}>
      <CircularProgress />
      <Typography variant="h5">
        {t("my_projects.my_projects_loading")}
      </Typography>
    </Stack>
  );

  const renderProjectList = () => (
    <Stack spacing={5}>
      <CardHeader headline={t("my_projects.headline")} />
      {(projects?.length ?? 0) > 0 ? (
        <List disablePadding>
          {projects?.map((project) => (
            <ProjectListItem
              key={`item-${project.id}`}
              testId="myProjectsViewListItem"
              id={project.id}
              name={project.name}
              organisatorId={project.organisatorId}
              slides={slides}
              visibility={project.visibility}
            />
          ))}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={handleDeleteClickButton}
              aria-label="delete"
              size="small"
              style={{
                margin: "0 1em 0 0",
                borderRadius: "50px",
                border: "1px solid",
                padding: "5px 10px",
                fontSize: "12px",
                textTransform: "uppercase",
                color: "rgba(33,87,122,255)",
              }}
            >
              <DeleteIcon fontSize="small" />
              {t("my_projects.delete_button_text")}
            </IconButton>
          </div>
        </List>
      ) : (
        <Alert severity="info">{t("my_projects.no_projects_message")}</Alert>
      )}
    </Stack>
  );

  const renderDeleteView = () => (
    <Stack spacing={5}>
      <CardHeader
        headline={t("my_projects.deletion_confirmations_view_title")}
      />
      {(projects?.length ?? 0) > 0 ? (
        <List disablePadding>
          {projects?.map((project) => (
            <ProjectListItemCheck
              key={`check-${project.id}`}
              id={project.id}
              testId={"myProjectsViewListItem"}
              name={project.name}
              organisatorId={project.organisatorId}
              slides={slides}
              onChange={handleDeleteCheck}
              checked={checkedProjects[project.id] || false}
            />
          ))}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={handleBackButton}
              aria-label="delete"
              size="small"
              style={{
                margin: "0 1em 0 0",
                borderRadius: "50px",
                padding: "5px 10px",
                fontSize: "12px",
                textTransform: "uppercase",
                color: "rgba(33,87,122,255)",
                border: "1px solid",
              }}
            >
              <ArrowBackIcon fontSize="small" />
              {t("my_projects.go_back_button")}
            </IconButton>
            <IconButton
              onClick={handleDelete}
              aria-label="delete"
              size="small"
              disabled={!isAnyProjectChecked}
              style={{
                margin: "0 1em",
                borderRadius: "50px",
                padding: "5px 10px",
                fontSize: "12px",
                textTransform: "uppercase",
                color: !isAnyProjectChecked ? "grey" : "red",
                border: "1px solid",
              }}
            >
              <DeleteIcon fontSize="small" />
              {t("my_projects.delete_button_text")}
            </IconButton>

            <ConfirmationDialog
              openUpdateDialog={open}
              handleUpdateDialogClose={handleDialogClose}
              actionOnConfirm={handleProjectDelete}
              testId="deleteDialog"
              confirmButtonText={t("my_projects.delete_button_text")}
            >
              <p>
                <b>
                  {t(
                    "my_projects.deletion_confirmations_dialog_view.title",
                  )}
                </b>
              </p>
              <b>
                {t("my_projects.deletion_confirmations_dialog_view.text1")}
              </b>
              <p>
                {t("my_projects.deletion_confirmations_dialog_view.text2")}
              </p>
            </ConfirmationDialog>
          </div>
        </List>
      ) : (
        <Alert severity="info">{t("my_projects.no_projects_message")}</Alert>
      )}
    </Stack>
  );

  const renderView = () => {
    if (!projects) {
      return renderLoading();
    }

    if (deleteProjectView) {
      return renderDeleteView();
    }

    return renderProjectList();
  };

  return (
    <Grow in={true}>
      <Box>
        <Container maxWidth="md" sx={{ mt: 3 }}>
          <CardComponent testid="myProjectsViewCard">
            {renderView()}
          </CardComponent>
        </Container>
      </Box>
    </Grow>
  );
};

export default MyProjectsView;
