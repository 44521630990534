import { LogLevel, Configuration } from "@azure/msal-browser";

const logLevel: LogLevel = LogLevel.Error;

const appIduri = process.env.REACT_APP_APPLICATION_ID_URI;

export const backendScope = `${appIduri}/Danify.Backend.Access`;

/**
 * @returns MSAL Configuration
 * For a full list of MSAL.js configuration parameters,
 * visit https://azuread.github.io/msal-browser/identities.html
 */
export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || "", // This is the ONLY mandatory field that you need to supply.
    authority: process.env.REACT_APP_AUTHORITY || "", // Defaults to "https://login.microsoftonline.com/common"

    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    clientCapabilities: ["CP1"], // this lets the resource owner know that this client is capable of handling claims challenge.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookies: true,
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      logLevel,
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

/**
 * @returns Resource Scopes for protected API endpoints
 */
export const protectedResources = {
  apiUsers: {
    endpoint: `${process.env.REACT_APP_BACKEND_API}/users`,
    scopes: {
      read: ["User.ReadBasic.All"],
    },
  },
  apiTasklists: {
    endpoint: `${process.env.REACT_APP_BACKEND_API}/tasklists`,
    scopes: {
      read: [backendScope],
    },
  },
  apiTasks: {
    endpoint: `${process.env.REACT_APP_BACKEND_API}`,
    scopes: {
      read: [backendScope],
    },
  },
  apiProject: {
    endpoint: `${process.env.REACT_APP_BACKEND_API}/projects/`,
    scopes: {
      write: [backendScope],
    },
  },
  apiTemplates: {
    endpoint: `${process.env.REACT_APP_BACKEND_API}/templates/`,
    scopes: {
      write: [backendScope],
    },
  },
  functionCSVImport: {
    endpoint: `${process.env.REACT_APP_CSV_FUNCTION}`,
    scopes: {
      write: [backendScope],
    },
  },
  apiPresentations: {
    endpoint: `${process.env.REACT_APP_BACKEND_API}/presentations/`,
    scopes: {
      write: [ backendScope ],
    }
  },
};

export const translationEndpoint = `${process.env.REACT_APP_BACKEND_API}/langs`;
export const chatEndpoint = `${process.env.REACT_APP_BACKEND_API}/chat`;
export const copilotEndpoint = `${process.env.REACT_APP_BACKEND_API}/copilot`;
export const featureRequestChatEndpoint = `${process.env.REACT_APP_BACKEND_API}/feature-request-chat`;
export const feedbackEndpoint = `${process.env.REACT_APP_BACKEND_API}/feedback`;

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [backendScope, "User.ReadBasic.All", "Tasks.ReadWrite"],
};
