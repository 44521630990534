import Dialog from "@mui/material/Dialog";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grow,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import DownloadIcon from "@mui/icons-material/Download";
import React, { useState } from "react";
import useTemplateApi from "../Api/TemplateApi";
import { useTranslation } from "react-i18next";
import CardHeader from "./CardHeader";
import CardElement from "./CardElement";
import { Icon } from "@fluentui/react";
import CloseIcon from "@mui/icons-material/Close";
import TextCarousel from "./TextCarousel";

interface Slide {
  title: string;
  name: string;
}

interface SlideResponse {
  base64File: string;
  tasklist: string;
}

interface CreatePresentationDialogProps {
  open: boolean;
  clicked: boolean;
  projectId: string;
  projectName: string;
  onClose: () => void;
  slides: Slide[];
}

const CreatePresentationDialog = (props: CreatePresentationDialogProps) => {
  const [downloadSlide, setDownloadSlide] = useState<SlideResponse | null>(
    null,
  );
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [chosenSlideIndex, setChosenSlideIndex] = useState<string>("");
  const [generateImages, setGenerateImages] = useState<boolean>(false);
  const [saveToDb, setSaveToDb] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [changeStep, setChangeStep] = useState<boolean>(false);
  const templateApi = useTemplateApi();
  const { t } = useTranslation();

  const handleGenerateImagesChange = () => {
    generateImages ? setGenerateImages(false) : setGenerateImages(true);
  };

  const handleSaveToDbChange = () => {
    saveToDb ? setSaveToDb(false) : setSaveToDb(true);
  };

  const handleTemplateSelectChange = (event: SelectChangeEvent) => {
    setChosenSlideIndex(event.target.value);
  };

  const createTemplate = (projectId: string) => {
    let masterSlideName = "";
    if (chosenSlideIndex != "") {
      masterSlideName = props.slides[parseInt(chosenSlideIndex)].name;
    }
    templateApi
      .createTemplate(projectId, masterSlideName, generateImages, saveToDb)
      .then((response) => {
        if (response?.body) {
          setDownloadSlide(response.body);
        }
      });
  };

  const handleDownloadClick = () => {
    setDownloadSuccess(true);
  };

  const handleOnClose = () => {
    props.onClose();
    setActiveStep(1);
    setGenerateImages(false);
    setChosenSlideIndex("");
    setDownloadSlide(null);
    setDownloadSuccess(false);
    setChangeStep(false);
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleOnClose}
        PaperProps={{ sx: { borderRadius: 3 } }}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <List>
          <ListItem
            secondaryAction={
              <IconButton onClick={handleOnClose}>
                <CloseIcon />
              </IconButton>
            }
            disablePadding
          >
            <ListItemText
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon iconName="PowerPointLogo" />
                <Typography
                  variant="overline"
                  sx={{
                    marginTop: "3px",
                    marginLeft: 1,
                  }}
                >
                  {props.projectName}
                </Typography>
              </Box>
            </ListItemText>
          </ListItem>
        </List>
        <Grow
          in={activeStep == 1 && props.clicked}
          onExited={() => {
            if (activeStep == 2) {
              setChangeStep(true);
            }
          }}
          unmountOnExit
          mountOnEnter
        >
          <Stack
            sx={{
              boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
              p: 7,
              pb: 3,
            }}
            spacing={5}
          >
            <Stack spacing={2}>
              <Divider>
                <CardHeader headline={t("my_projects.create_presentation")} />
              </Divider>
              <Box>
                <Typography variant="caption">
                  {t(
                    "my_projects.template_dialog.download_presentation.caption",
                  )}
                </Typography>
              </Box>
            </Stack>
            <CardElement
              headline={t(
                "my_projects.template_dialog.download_presentation.choose_template",
              )}
            >
              <FormGroup>
                <Select
                  value={chosenSlideIndex}
                  onChange={handleTemplateSelectChange}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>{t("my_projects.template_dialog.without_template")}</em>
                  </MenuItem>
                  {props.slides.map((slide, index) => (
                    <MenuItem value={index.toString()}>{slide.title}</MenuItem>
                  ))}
                </Select>
              </FormGroup>
            </CardElement>
            <CardElement
              headline={t(
                "my_projects.template_dialog.download_presentation.additional_options",
              )}
            >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={generateImages}
                  onChange={handleGenerateImagesChange}
                  label={t("my_projects.template_dialog.generate_images")}
                />
                <FormControlLabel
                    control={<Checkbox />}
                    checked={saveToDb}
                    onChange={handleSaveToDbChange}
                    label={t("my_projects.template_dialog.save_to_db")}
                />
              </FormGroup>
            </CardElement>
            <DialogActions>
              <Button
                onClick={() => {
                  createTemplate(props.projectId);
                  setActiveStep(2);
                }}
              >
                {t("my_projects.template_dialog.create")}
              </Button>
            </DialogActions>
          </Stack>
        </Grow>
        <Grow in={activeStep == 2 && changeStep} unmountOnExit mountOnEnter>
          <Stack
            sx={{
              boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
              p: 7,
              pb: 3,
            }}
            spacing={5}
          >
            <Divider>
              <CardHeader
                headline={t(
                  "my_projects.template_dialog.download_presentation.headline",
                )}
              />
            </Divider>
            {downloadSlide ? (
              <Box>
                <a
                  href={`data:file/pptx;base64,${downloadSlide.base64File}`}
                  download={`${downloadSlide.tasklist}.ppt`}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <IconButton onClick={handleDownloadClick}>
                    {downloadSuccess ? (
                      <DownloadDoneIcon color={"primary"} />
                    ) : (
                      <Tooltip
                        title={t(
                          "my_projects.template_dialog.download_presentation.download",
                        )}
                      >
                        <DownloadIcon color={"primary"} />
                      </Tooltip>
                    )}
                  </IconButton>
                </a>
                {`${downloadSlide.tasklist}.ppt`}
              </Box>
            ) : (
              <Stack spacing={5}>
                <TextCarousel
                  texts={t("my_projects.create_presentation_loading_texts", {
                    returnObjects: true,
                  })}
                />
                <LinearProgress />
                {t(
                  "my_projects.template_dialog.download_presentation.loading_text",
                )}
              </Stack>
            )}
            <DialogActions>
              <Button onClick={handleOnClose}>
                {t("my_projects.template_dialog.download_presentation.close")}
              </Button>
            </DialogActions>
          </Stack>
        </Grow>
      </Dialog>
    </>
  );
};

export default CreatePresentationDialog;
