import { Message } from "../../core/types/ai-function";
import { usePostFeedback } from "../../../../Api/FeedbackApi";
import React, { useState, useEffect } from 'react';
import Button from "@mui/material/Button";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from "react-i18next";


export interface FeedbackButtonsProps {
    message: Message;
}

export function FeedbackButtons({ message }: FeedbackButtonsProps) {
    const { t } = useTranslation();

    const postFeedback = usePostFeedback();
    const feedbackPositive = 1;
    const feedbackNeutral = 0;
    const feedbackNegative = -1;

    const [feedback, setFeedback] = useState<typeof feedbackPositive | typeof feedbackNegative | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [comment, setComment] = useState('');
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [progress, setProgress] = useState(100);
    const [showProgressBar, setShowProgressBar] = useState(true); // New state to control the visibility of the progress bar

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleTextFieldFocus = () => {
        if (timer) {
            clearInterval(timer); // Clear the existing timer
            setTimer(null); // Reset the timer state
        }
        setShowProgressBar(false); // Hide the progress bar
    };

    useEffect(() => {
        if (open && !timer && showProgressBar) { // Only start the countdown if the popover is open, there's no active timer, and the progress bar should be shown
            const countdown = setInterval(() => {
                setProgress((oldProgress) => {
                    const decrement = 1;
                    const newProgress = oldProgress - decrement;
                    if (newProgress <= 0) {
                        clearInterval(countdown);
                        handleClose();
                        return 0;
                    }
                    return newProgress;
                });
            }, 100);

            setTimer(countdown);
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [open, timer, showProgressBar]); // Add showProgressBar to the dependency array

    const sendFeedback = (
        traceId: string | undefined,
        name: string | undefined,
        feedbackValue: typeof feedbackPositive | typeof feedbackNeutral | typeof feedbackNegative,
        comment: string | undefined
    ) => {
        if (!traceId) {
            console.log("traceId is undefined");
            return;
        }
        postFeedback(traceId, name, feedbackValue, comment);
    };

    const handleUpvote = (event: React.MouseEvent<HTMLButtonElement>) => {
        sendFeedback(message.traceId, undefined, feedbackPositive, undefined);
        setFeedback(feedbackPositive);
        setAnchorEl(event.currentTarget); // Set the anchor element for the Popover
    };

    const handleDownvote = (event: React.MouseEvent<HTMLButtonElement>) => {
        sendFeedback(message.traceId, undefined, feedbackNegative, undefined);
        setFeedback(feedbackNegative);
        setAnchorEl(event.currentTarget); // Set the anchor element for the Popover
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default action to avoid submitting the form if it's part of one
            handleCommentSubmit(); // Call the function to submit the comment
        }
    };

    const handleCommentSubmit = () => {
        if (!comment) {
            return;
        }
        sendFeedback(message.traceId, undefined, feedbackNeutral, comment);
        setAnchorEl(null); // Close the Popover
    };

    const handleClose = () => {
        setAnchorEl(null); // Close the Popover when clicking away
    };


    return (
        <div className="buttons-container">
            <Button
                startIcon={<ThumbUpIcon />}
                onClick={handleUpvote}
                disabled={feedback !== null}
                sx={{
                    color: "primary.light",
                    "&.Mui-disabled": {
                        color: feedback === feedbackPositive ? "primary.light" : "",
                        opacity: feedback === feedbackPositive ? 0.6 : 0.75,
                    },
                }}
            />

            <Button
                startIcon={<ThumbDownIcon />}
                onClick={handleDownvote}
                disabled={feedback !== null}
                sx={{
                    color: "primary.light",
                    "&.Mui-disabled": {
                        color: feedback === feedbackNegative ? "primary.light" : "",
                        opacity: feedback === feedbackNegative ? 0.6 : 0.75,
                    },
                }}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {<LinearProgress variant="determinate" value={progress} style={{ visibility: showProgressBar ? 'visible' : 'hidden' }} />}
                <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '300px' }}>
                    <TextField
                        label={t("feedback.tracing.add_comment")}
                        variant="outlined"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        onFocus={handleTextFieldFocus}
                        onKeyDown={handleKeyPress}
                        margin="normal"
                        style={{ width: '90%' }}
                        InputLabelProps={{
                            style: { fontSize: '0.875rem' },
                        }}
                    />
                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button onClick={handleClose} variant="outlined" color="primary">
                            {t("feedback.tracing.cancel")}
                        </Button>
                        <Button onClick={handleCommentSubmit} variant="contained" color="primary">
                            {t("feedback.tracing.send_feedback")}
                        </Button>
                    </div>
                </div>
            </Popover>
        </div>
    )

}