import React, { useEffect, useState } from "react";
import { Task } from "../Utils/Types/Task";
import { Backdrop, Box, CircularProgress, Grow } from "@mui/material";
import { protectedResources } from "../AuthModule";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import TaskListStep from "../Components/TaskListStep";
import ContactsStep from "../Components/ContactsStep";
import ProjectNameStep from "../Components/ProjectNameStep";
import StepperComponent from "../Components/StepperComponent";
import NavigationButtons from "../Components/NavigationButtons";
import ProjectCreationDialog from "../Components/ProjectCreationDialog";
import CardComponent from "../Components/CardComponent";
import CardHeader from "../Components/CardHeader";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { TodoList } from "../Model/TodoList";
import { useImmer } from "use-immer";
import { ErrorRounded } from "@mui/icons-material";

interface ProjectCreation {
  name: string;
  emails: string[];
  listType: "import" | "todo";
  tasks: Task[];
  visibility: string;
  isHideFinishedTasks: boolean;
  emailNotificationsEnabled: boolean;
  host: string;
}

const StartProjectView = (props: any) => {
  //DATA
  const [todoLists] = useState(props.taskListData);
  const [selectedList, setSelectedList] = useState<TodoList | null>(null);
  const [tasks, setTasks] = useImmer<Task[]>([]);
  const [checked, setChecked] = useState(false);
  const [isTasksLoading, setTasksLoading] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [suggestedProjectNames, setSuggestedProjectNames] = useState<string[]>(
    [],
  );
  const [visibility, setVisibility] = useState<string>("private");
  const [isPrivate, setIsPrivate] = useState(true);
  const [sendNotification, setSendNotification] = useState(false);
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] =
    useState(false);

  const host = window.location.protocol + "//" + window.location.host;

  //UI
  const [activeStep, setActiveStep] = useState(0);
  const [showContent, setShowContent] = useState<boolean>(true);
  const [inputError, setInputError] = useState<boolean>(false);
  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const { t } = useTranslation();

  const navigationSteps = [
    { title: t("start_project.navigation_steps.select_list") }, //Liste auswählen
    { title: t("start_project.navigation_steps.assign_contacts") }, //kontakte
    { title: t("start_project.navigation_steps.start_project") },
  ];

  // Login Request / Scopes
  const { execute } = useFetchWithMsal(
    {
      scopes: protectedResources.apiTasklists.scopes.read,
    },
    () => {
      return {
        isLoading: false,
        data: {
          status: 200,
          body: [
            {
              categories: ["Cat1", "Cat2"],
              createdDateTime: new Date().toISOString(),
              hasAttachments: false,
              note: "Test Note",
              id: "1",
              importance: "normal",
              isReminderOn: false,
              lastModifiedDateTime: new Date().toISOString(),
              status: "NotStarted",
              title: "Do something",
            },
            {
              categories: ["Cat1", "Cat2"],
              createdDateTime: new Date().toISOString(),
              hasAttachments: false,
              note: "Test Note",
              id: "2",
              importance: "normal",
              isReminderOn: false,
              lastModifiedDateTime: new Date().toISOString(),
              status: "NotStarted",
              title: "Do something2",
            },
            {
              categories: ["Cat1", "Cat2"],
              createdDateTime: new Date().toISOString(),
              hasAttachments: false,
              note: "Test Note",
              id: "3",
              importance: "normal",
              isReminderOn: false,
              lastModifiedDateTime: new Date().toISOString(),
              status: "NotStarted",
              title: "Do something3",
            },
          ],
        },
        error: null,
        execute: () => {
          return Promise.resolve({
            status: 200,
            body: [
              {
                categories: ["Cat1", "Cat2"],
                createdDateTime: new Date().toISOString(),
                hasAttachments: false,
                note: "Test Note",
                id: "1",
                importance: "normal",
                isReminderOn: false,
                lastModifiedDateTime: new Date().toISOString(),
                status: "NotStarted",
                title: "Do something",
              },
              {
                categories: ["Cat1", "Cat2"],
                createdDateTime: new Date().toISOString(),
                hasAttachments: false,
                note: "Test Note",
                id: "2",
                importance: "normal",
                isReminderOn: false,
                lastModifiedDateTime: new Date().toISOString(),
                status: "NotStarted",
                title: "Do something2",
              },
              {
                categories: ["Cat1", "Cat2"],
                createdDateTime: new Date().toISOString(),
                hasAttachments: false,
                note: "Test Note",
                id: "3",
                importance: "normal",
                isReminderOn: false,
                lastModifiedDateTime: new Date().toISOString(),
                status: "NotStarted",
                title: "Do something3",
              },
            ],
          });
        },
      };
    },
  );

  useEffect(() => {
    if (selectedList != null) {
      setTasksLoading(true);
      fetchTasks().then(() => setTasksLoading(false));
    }
  }, [selectedList]);

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const fetchTasks = async () => {
    try {
      setIsBusy(true);
      if (selectedList != null) {
        let baseUri = protectedResources.apiTasks.endpoint;
        let uri =
          selectedList.type === "import"
            ? `${baseUri}/tasklists/imported/${selectedList.id}/tasks`
            : `${baseUri}/tasklists/todo/${selectedList.id}/tasks`;

        await execute("GET", uri).then(async (response: any) => {
          setTasks(response.body);
          setIsBusy(false);
        });
      }
    } catch (error) {
      console.error(
        "Fehler beim Abrufen der Tasks zur gewählten Liste:",
        error,
      );
      return <ErrorRounded />;
    }
  };

  const submitProject = async () => {
    try {
      if (selectedList != null && selectedContact.length > 0) {
        const taskList = checked
          ? tasks.filter((t) => t.status === "NotStarted")
          : [...tasks];
        const data: ProjectCreation = {
          name: projectName,
          emails: [selectedContact],
          listType: selectedList.type,
          tasks: taskList,
          visibility: visibility,
          isHideFinishedTasks: checked,
          emailNotificationsEnabled: emailNotificationsEnabled,
          host: host,
        };
        await execute(
          "POST",
          protectedResources.apiProject.endpoint + selectedList.id,
          data,
        ).then((response: any) => {
          if (response.status === 409) {
            setShowBackdrop(false);
            setInputError(true);
            setSuggestedProjectNames(
              generateRandomProjectNames(
                false,
                selectedList.displayName,
                selectedContact,
              ),
            );
          } else if (response.status === 201) {
            // TODO: Show success with UUID and link to project
            handleFinishProjectCreation();
          }
        });
      } else {
        setShowBackdrop(false);
      }
    } catch (error) {
      console.error("Fehler beim Anlegen des Projekts:", error);
    }
  };

  const handleFinishProjectCreation = () => {
    setShowBackdrop(false);
    setShowContent(false);
  };

  const handleListChange = (event: any, newValue: TodoList | null) => {
    if (newValue != null) {
      setSelectedList(newValue);
      setInputError(false);
    } else {
      setSelectedList(null);
      setSuggestedProjectNames([]);
      setInputError(true);
    }
  };

  const handleTasksChange = (event: any) => {
    setChecked(event.target.checked);
  };

  function handleSwapItems(currentPos: number, newPos: number) {
    setTasks((draft: Task[] | null) => {
      if (draft === null) {
        return draft;
      } else {
        const result = draft;
        let movedItem = result[currentPos];

        result.splice(currentPos, 1);
        result.splice(newPos, 0, movedItem);
        return result;
      }
    });
  }

  const handleContactslistChange = (
    event: any,
    selectedContact: string | null,
  ) => {
    if (selectedContact != null) {
      setSelectedContact(filterInvalidEmails(selectedContact));
      setInputError(false);
    } else {
      setSelectedContact("");
      setInputError(true);
    }
  };

  const handleVisibilityChange = (isPrivate: boolean) => {
    if (isPrivate) {
      setVisibility("private");
      setIsPrivate(true);
    } else {
      setVisibility("public");
      setIsPrivate(false);
    }
  };
  const handleContactInform = (sendNotification: boolean) => {
    if (sendNotification) {
      setEmailNotificationsEnabled(true);
      setSendNotification(true);
    } else {
      setEmailNotificationsEnabled(false);
      setSendNotification(false);
    }
  };

  const filterInvalidEmails = (email: string) => {
    const validEmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (validEmailRegex.test(email.trim())) {
      return email;
    } else {
      return "";
    }
  };

  const handleOnNextClicked = async () => {
    await timeout(300);
    switch (activeStep) {
      case 0:
        if (selectedList != null) {
          setActiveStep(1);
          setInputError(false);
        } else {
          setInputError(true);
        }
        break;
      case 1:
        if (selectedList != null && selectedContact.length > 0) {
          setSuggestedProjectNames(
            generateRandomProjectNames(
              true,
              selectedList.displayName,
              selectedContact,
            ),
          );
          setActiveStep(2);
          setInputError(false);
        } else {
          setInputError(true);
        }
        break;
    }
    resetFocus();
  };

  const handleOnSubmitClicked = () => {
    if (!projectName.trim()) {
      setInputError(true);
      return;
    }
    setShowBackdrop(true);
    submitProject().then(() => {});
  };

  const handleOnBackClicked = async () => {
    await timeout(300);
    setInputError(false);
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
    resetFocus();
  };

  const resetFocus = () => {
    if (document?.activeElement) {
      (document.activeElement as HTMLInputElement).blur();
    }
  };
  const handleNavigationClick = (navigationStep: number) => {
    setInputError(false);
    setActiveStep(navigationStep);
  };

  const returnToPage = (pageNumber: number) => {
    switch (pageNumber) {
      case 0:
        setActiveStep(0);
        setSelectedList(null);
        break;
      case 1:
        setActiveStep(1);
        break;
    }
  };

  const removeAssignee = () => {
    setSelectedContact("");
  };

  const restartPage = () => {
    setSelectedContact("");
    setSelectedList(null);
    setActiveStep(0);
    setInputError(false);
    setShowContent(true);
    setShowBackdrop(false);
  };

  const capitalizeFirstLetter = (word: string) =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

  const formatContactName = (contact: string) => {
    if (contact.includes("@")) {
      contact = contact.substring(0, contact.indexOf("@"));
    }
    if (contact.includes(".")) {
      const parts = contact
        .split(".")
        .map((part) => (part.length > 0 ? capitalizeFirstLetter(part) : ""));
      contact = parts.join(" ");
    }
    return contact;
  };

  const generateRandomProjectNames = (
    includeProjectName: boolean,
    projectName: string,
    selectedContact: string,
  ) => {
    const resultArray: string[] = [];
    let randomProjectName: string = "";
    let generatedNames: number = 0;

    if (includeProjectName) {
      resultArray.push(projectName);
      if (selectedContact.length > 0) {
        let formattedContact = formatContactName(selectedContact);
        formattedContact = `${projectName} - ${formattedContact.replace(".", " ")}`;
        setProjectName(formattedContact);
      }
    }

    while (generatedNames < 2) {
      randomProjectName = `${projectName} ${Math.floor(Math.random() * 101)}`;
      if (
        randomProjectName !== projectName &&
        !resultArray.includes(randomProjectName)
      ) {
        resultArray.push(randomProjectName);
        generatedNames++;
      }
    }
    return resultArray;
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <TaskListStep
            selectedList={selectedList}
            handleListChange={handleListChange}
            handleTasksChange={handleTasksChange}
            todoLists={todoLists}
            inputError={inputError}
            tasks={tasks}
            isTasksLoading={isTasksLoading}
            handleSwapItems={handleSwapItems}
            checked={checked}
          />
        );
      case 1:
        return (
          <ContactsStep
            selectedContact={selectedContact}
            handleContactlistChange={handleContactslistChange}
            inputError={inputError}
            selectedList={selectedList}
            returnToPage={returnToPage}
            isPrivate={isPrivate}
            handleVisibilityChange={handleVisibilityChange}
            handleContactInform={handleContactInform}
            sendNotification={sendNotification}
          />
        );
      case 2:
        return (
          <ProjectNameStep
            projectName={projectName}
            inputError={inputError}
            suggestedProjectNames={suggestedProjectNames}
            setProjectName={setProjectName}
            selectedContacts={[selectedContact]}
            removeAssignee={removeAssignee}
            handleOnAssignContactsClicked={handleOnBackClicked}
            setInputError={setInputError}
          />
        );
    }
  };

  return (
    <Box>
      <Grow in={showContent} mountOnEnter unmountOnExit>
        <Container maxWidth="md" sx={{ mt: 3 }}>
          <CardComponent>
            <CardHeader headline={t("start_project.headline")}>
              <StepperComponent
                activeStep={activeStep}
                navigationSteps={navigationSteps}
                onNavigationClick={handleNavigationClick}
                onBackClicked={handleOnBackClicked}
                onSubmitClicked={handleOnSubmitClicked}
                onNextClicked={handleOnNextClicked}
                isLastStep={false}
              />
            </CardHeader>
            {getStepContent()}
            <NavigationButtons
              activeStep={activeStep}
              onBackClicked={handleOnBackClicked}
              onNextClicked={handleOnNextClicked}
              onSubmitClicked={handleOnSubmitClicked}
              isLastStep={activeStep === navigationSteps.length - 1}
              canProjectBeCreated={
                projectName !== "" && selectedContact.length > 0
              }
              isLoading={isBusy}
            />
          </CardComponent>
        </Container>
      </Grow>
      {!showContent && (
        <ProjectCreationDialog
          projectName={projectName}
          host={host}
          restartPage={restartPage}
        />
      )}
      <Backdrop open={showBackdrop} onClick={() => setShowBackdrop(false)}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default StartProjectView;
